import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from './customBaseQuery';
import {ReviewGiftDTO, ReviewPersonGift, ReviewsPersonGift} from '../models/gifts.models';

export const personGiftController = createApi({
  reducerPath: 'personGiftController',
  baseQuery: customBaseQuery,
  tagTypes: ['PersonGift'],
  endpoints: (builder) => ({
    getAllReviewGift: builder.query<ReviewsPersonGift, ''>({
      query: () => ({
        url: 'person_gift'
      }),
      providesTags: ['PersonGift'],
    }),
    getReviewGiftByID: builder.query<ReviewsPersonGift, string>({
      query: (id) => ({
        url:`person_gift/${id}`
    }),
      providesTags: (result, error, id) => [{ type: 'PersonGift', id }],
    }),
    getAllReviewGiftByUserID: builder.query<ReviewPersonGift, string>({
      query: (id) => ({
        url:`person_gift/${id}`
      }),
      providesTags: (result, error, id) => [{ type: 'PersonGift', id }],
    }),
    createReviewGift: builder.mutation<ReviewPersonGift, ReviewGiftDTO>({
      query: (body) => ({
        url: 'person_gift',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PersonGift'],
    }),
    deleteReviewGift: builder.mutation<any, string>({
      query: (id) => ({
        url: `person_gift/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'PersonGift', id }],
    }),
    approveReviewGift: builder.mutation<any, string>({
      query: (id) => ({
        url: `person_gift/approve/${id}`,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'PersonGift', id }],
    }),
    clearingReviewGift: builder.mutation<any, string>({
      query: (id) => ({
        url: `person_gift/clearing/${id}`,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'PersonGift', id }],
    }),
    refuseReviewGift: builder.mutation<any, string>({
      query: (id) => ({
        url: `person_gift/refuse/${id}`,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'PersonGift', id }],
    }),
  }),
});

export const {
  useGetAllReviewGiftQuery,
  useGetReviewGiftByIDQuery,
  useCreateReviewGiftMutation,
  useDeleteReviewGiftMutation,
  useApproveReviewGiftMutation,
  useClearingReviewGiftMutation,
  useRefuseReviewGiftMutation,
} = personGiftController;

