import {createApi} from '@reduxjs/toolkit/query/react';
import {customBaseQuery} from './customBaseQuery';
import {IGifts, GiftView} from '../models/gifts.models';

export const giftsController = createApi({
    reducerPath: 'giftsController',
    baseQuery: customBaseQuery,
    tagTypes: ['Gifts'],
    endpoints: (builder) => ({
        getAllGifts: builder.query<IGifts, ''>({
            query: () => ({
                url: 'gift'
            }),
            providesTags: ['Gifts'],
        }),
        getAllGiftsByUserId: builder.query<IGifts, string>({
            query: (id) => ({
                url: `gift/${id}`
            }),
            providesTags: ['Gifts'],
        }),
        getGiftByID: builder.query<GiftView, string>({
            query: (id) => ({
                url: `gift/${id}`
            }),
            providesTags: (result, error, id) => [{type: 'Gifts', id}],
        }),
        createGift: builder.mutation<GiftView, any>({
            query: (body) => ({
                url: 'gift',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Gifts'],
        }),
        editGift: builder.mutation<GiftView, { id: string; body: any }>({
            query: ({id, body}) => ({
                url: `gift/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, {id}) => [{type: 'Gifts', id}],
        }),
        deleteGift: builder.mutation<any, string>({
            query: (id) => ({
                url: `gift/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{type: 'Gifts', id}],
        }),
    }),
});

export const {
    useGetAllGiftsQuery,
    useGetGiftByIDQuery,
    useCreateGiftMutation,
    useEditGiftMutation,
    useDeleteGiftMutation,
    useGetAllGiftsByUserIdQuery
} = giftsController;

