import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from './customBaseQuery';
import {Activity} from '../models/activity.models';

export const personActivityController = createApi({
  reducerPath: 'personActivityController',
  baseQuery: customBaseQuery,
  tagTypes: ['PersonActivity'],
  endpoints: (builder) => ({
    getAllReviewActivity: builder.query<Activity, ''>({
      query: () => ({
        url: 'person_activity'
      }),
      providesTags: ['PersonActivity'],
    }),
    getReviewActivityByID: builder.query<any, string>({
      query: (id) => ({
        url:`person_activity/${id}`
    }),
      providesTags: (result, error, id) => [{ type: 'PersonActivity', id }],
    }),
    getAllReviewActivityByUserID: builder.query<any, string>({
      query: (id) => ({
        url:`person_activity/${id}`
      }),
      providesTags: (result, error, id) => [{ type: 'PersonActivity', id }],
    }),
    createReviewActivity: builder.mutation<any, any>({
      query: (body) => ({
        url: 'person_activity',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PersonActivity'],
    }),
    deleteReviewActivity: builder.mutation<any, string>({
      query: (id) => ({
        url: `person_activity/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'PersonActivity', id }],
    }),
    approveReviewActivity: builder.mutation<any, string>({
      query: (id) => ({
        url: `person_activity/approve/${id}`,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'PersonActivity', id }],
    }),
    clearingReviewActivity: builder.mutation<any, string>({
      query: (id) => ({
        url: `person_activity/clearing/${id}`,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'PersonActivity', id }],
    }),
    refuseReviewActivity: builder.mutation<any, string>({
      query: (id) => ({
        url: `person_activity/refuse/${id}`,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'PersonActivity', id }],
    }),
  }),
});

export const {
  useGetAllReviewActivityQuery,
  useGetReviewActivityByIDQuery,
  useCreateReviewActivityMutation,
  useDeleteReviewActivityMutation,
  useApproveReviewActivityMutation,
  useClearingReviewActivityMutation,
  useRefuseReviewActivityMutation,
} = personActivityController;

