import React, { FC, useEffect, useState, useContext } from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { kbController } from "../../../services/kb.controller";
import { convertContent } from "../ArticlePage/ArticlePage";
import { AppContext } from "../../shared/AppContextProvider";
import { KbContext } from "../KbContextProvider/KbContextProvider";
import { UpdateKbArticleRequest } from "../../../models/kb.models";
import { regEx } from "../../../constants/regEx";
import { LoadProgress } from "../../common/LoadProgress/LoadProgress";

import TextEditor from "../TextEditor/TextEditor";
import DialogWrapper from "../../common/Modals/DialogWrapper/DialogWrapper";
import ModalButtons from "../../common/Buttons/ModalButtons/ModalButtons";
import InputWrapper from "../../common/Inputs/InputWrapper/InputWrapper";
import KbListSequence from "./material/KbListSequence";
import KbTypeSequence from "./material/KbTypeSequence";

import {
  Paper,
  InputBase,
  Stack,
  SelectChangeEvent,
  Divider,
} from "@mui/material";

interface CreateArticleModalProps {
  open: boolean;
  type: "create" | "edit";
  sectionId: string;
  articleId?: string | null;
  parentArticleId?: string | null;
  handleModal: () => void;
  handleCompleted?: () => void;
}

export const CreateArticleModal: FC<CreateArticleModalProps> = ({
  open,
  type,
  sectionId,
  articleId = null,
  parentArticleId = null,
  handleModal,
  handleCompleted = () => {},
}) => {
  const { isMobile } = useContext(AppContext);
  const { handleSnackbar } = useContext(KbContext);

  const isCreate = type === "create";

  const [isLoad, setIsLoad] = useState<boolean>(false)
  const [countsSequenceArticle, setCountsSequenceArticle] = useState<number>(0)

  const [title, setTitle] = useState<string>("");
  const handleTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const [activeSequence, setActiveSequence] = useState<string>("")
  const handleActiveSequence = (e: SelectChangeEvent) => {
    setActiveSequence(e.target.value);
  };

	const [typeShift, setTypeShift] = useState<'replacement' | 'offset'>('replacement')
  const handleTypeShift = (e: SelectChangeEvent) => {
    setTypeShift(e.target.value as 'replacement' | 'offset')
  };

  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())

  const getCountsArticleSequence = () => {    
    kbController
      .countsArticlesSequence(sectionId, parentArticleId)
      .then((res) => {        
        setCountsSequenceArticle(Number(res.data));
      })
      .catch(() => {
        handleSnackbar("error", "Не удалось получить информацию по статье");
        handleModal();
      })
  }

  const getInfoArticle = () => {
    getCountsArticleSequence()
    if (!isCreate && articleId) {
      setIsLoad(true)
      kbController
        .oneArticle(articleId, sectionId)
        .then((res) => {
          setTitle(res.data.name)
          setActiveSequence(!!res.data.sequenceNumber ? String(res.data.sequenceNumber) : "")          
          setEditorState(
            EditorState.createWithContent(
              convertFromRaw(JSON.parse(convertContent(res.data.content)))
            )
          )
        })
        .catch(() => {
          handleSnackbar("error", "Не удалось получить информацию по статье");
          handleModal();
        })
        .finally(() => {
          setIsLoad(false)
        });
    }
  }

  const createArticle = (formData: FormData) => {
    setIsLoad(true)
    kbController
      .createArticle(sectionId, formData)
      .then(() => {
        handleCompleted()
      })
      .catch(() => {
        handleSnackbar("error");
      })
      .finally(() => {
        setIsLoad(false)
        handleModal();
      })
  }

  const editArticleService = (body: UpdateKbArticleRequest) => {
    if (articleId) {
      setIsLoad(true)
      kbController
        .editArticle(sectionId, articleId, body)
        .then(() => {
          handleCompleted()
        })
        .catch(() => {
          handleSnackbar("error");
        })
        .finally(() => {
          setIsLoad(false)
          handleModal();
        })
    }
  };

  const sendArticle = () => {
    const infoArticle: UpdateKbArticleRequest = {
      content: convertEditorState(),
      name: title,
      sequenceNumber: activeSequence,
      isReplacement: typeShift === 'replacement',
      isGroupArticle: false,
    }
    if (isCreate) {
			const body = articleId ? {...infoArticle, idParentArticle: articleId} : infoArticle      
      const formData = new FormData();
      formData.append(
        "request",
        new Blob(
          [ JSON.stringify(body) ],
          { type: "application/json" }
        )
      );
      createArticle(formData)
    } else {
      editArticleService(infoArticle);
    }
  }

  const convertEditorState = () => {
    return JSON.stringify(convertToRaw(editorState.getCurrentContent()));
  };

  const disableSendButton =
    regEx.isEmpty.test(title) ||
    regEx.isEmpty.test(activeSequence) ||
    regEx.isEmpty.test(editorState.getCurrentContent().getPlainText());

  useEffect(() => {
    getInfoArticle()
  }, []);

  const createModalButtons = (
    <ModalButtons
      handleCancel={handleModal}
      handleSend={sendArticle}
      fullWidth
      nameButtonSend="Сохранить"
      justifyContent="flex-end"
      disableSend={disableSendButton}
    />
  );

  return (
    <DialogWrapper
      width={isMobile ? '450px' : '750px'}
      openModal={open}
      handleModal={handleModal}
      buttons={createModalButtons}
      contentDividers
    >
      { isLoad ?
        <LoadProgress/> :
        <Stack gap={3}>

          <InputBase
            placeholder="Введите название статьи"
            fullWidth
            multiline
            sx={{
              fontSize: "32px",
              fontWeight: 700,
              lineHeight: "40px",
            }}
            value={title}
            onChange={handleTitle}
          />

          <Divider/>


          <InputWrapper
            flexDirection={isMobile ? 'column' : 'row'}
            title="Порядковый номер раздела"
            element={
							<Stack flexDirection={'row'} gap={2}>
								<KbListSequence 
									value={activeSequence}
									countsSequence={isCreate ? (countsSequenceArticle + 1) : countsSequenceArticle}
									onChange={handleActiveSequence}
								/>
								<KbTypeSequence
									value={typeShift}
									onChange={handleTypeShift}
								/>
							</Stack>
            }
          /> 

          <Divider/>

          <Paper
            sx={{
              boxShadow: "none",
              border: "1px solid rgba(241, 247, 255, 0.12)",
            }}
          >
            <TextEditor
              readOnly={false}
              setEditorState={setEditorState}
              editorState={editorState}
            />
          </Paper>
        </Stack>
      }
    </DialogWrapper>
  );
};

export default CreateArticleModal;
