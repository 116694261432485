import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from './customBaseQuery';
import {Activity} from '../models/activity.models';

export const activityController = createApi({
  reducerPath: 'activityController',
  baseQuery: customBaseQuery,
  tagTypes: ['Activity'],
  endpoints: (builder) => ({
    getAllActivity: builder.query<Activity, ''>({
      query: () => ({
        url: 'activity'
      }),
      providesTags: ['Activity'],
    }),
    getActivityByID: builder.query<any, string>({
      query: (id) => ({
        url:`activity/${id}`
    }),
      providesTags: (result, error, id) => [{ type: 'Activity', id }],
    }),
    createActivity: builder.mutation<any, any>({
      query: (body) => ({
        url: 'activity',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Activity'],
    }),
    editActivity: builder.mutation<any, { id: string; body: any }>({
      query: ({ id, body }) => ({
        url: `activity/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Activity', id }],
    }),
    deleteActivity: builder.mutation<any, string>({
      query: (id) => ({
        url: `activity/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Activity', id }],
    }),
  }),
});

export const {
  useGetAllActivityQuery,
  useGetActivityByIDQuery,
  useCreateActivityMutation,
  useEditActivityMutation,
  useDeleteActivityMutation,
} = activityController;

