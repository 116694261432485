import React, {FC} from 'react';
import HeroSection from './HeroSection/HeroSection';
import TableSection from './TableSection/TableSection';
import Snackbars from '../common/Snackbars/Snackbars';
import {useAppSelector} from '../../hooks/useAppSelector';
import {useDispatch} from 'react-redux';
import {hideSnackbar} from '../../reducer/snackbarSlice';

export const Bonuses: FC = () => {
    const dispatch = useDispatch();
    const { isOpen, message, type, position } = useAppSelector((state) => state.snackbarReducer);

    return (
        <>
            <HeroSection />
            <TableSection />
            <Snackbars
                open={isOpen}
                setOpen={() => dispatch(hideSnackbar())}
                type={type}
                position={position}
                message={message}
            />
        </>
    );
};

export default Bonuses;