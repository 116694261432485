import React, {FC} from 'react';
import Container from '@mui/material/Container';
import HeaderButtons from './HeaderButtons/HeaderButtons';


export const TableSection: FC = () => {

    return (
        <Container sx={{mt: 2, padding: 0}}>
            <HeaderButtons />
        </Container>
    );
};

export default TableSection;