import React, { FC, useEffect, useState, useContext } from "react";
import { kbController } from "../../../services/kb.controller";
import { KbContext } from "../KbContextProvider/KbContextProvider";
import { KbArticleListView } from "../../../models/kb.models";
import { LoadProgress } from "../../common/LoadProgress/LoadProgress";

import DialogWrapper from "../../common/Modals/DialogWrapper/DialogWrapper";
import ModalButtons from "../../common/Buttons/ModalButtons/ModalButtons";
import KbList from "./material/KbList";

import {
  Typography,
  Stack,
  SelectChangeEvent,
} from "@mui/material";

interface Sections {
  id: string;
  name: string;
  sequenceNumber: number;
}

interface GroupArticles {
  id: string;
  name: string;
  fullSequenceNumber: string;
}

interface CreateArticleModalProps {
  open: boolean;
  type: 'move' | 'duplication';
  article: KbArticleListView;
  handleModal: () => void;
  handleCompleted: () => void;
}

export const MoveArticleModal: FC<CreateArticleModalProps> = ({
  open,
  type,
  article,
  handleModal,
  handleCompleted = () => {},
}) => {
  const { handleSnackbar } = useContext(KbContext);
  const isMove = type === 'move'

  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [sections, setSections] = useState<Sections[]>([]);
  const [sectionId, setSectionId] = useState<string>('');
  const [articles, setArticles] = useState<GroupArticles[]>([]);
  const [articleId, setArticleId] = useState<string>('');

  const getSections = () => {
    setIsLoad(true)
    kbController
      .onlySections()
      .then((res) => {
        setSections(res.data.items)
      })
      .catch(() => {
        handleSnackbar("error");
        handleModal()
      })
      .finally(() => {
        setIsLoad(false)
      })
  }

  const getArticles = (sectionId: string) => {
    if (sectionId) {
      kbController
        .allGroupArticles(sectionId)
        .then((res) => {
          setArticles(res.data.items)
        })
        .catch(() => {
          handleSnackbar("error");
          handleModal()
        });
    }
  }

  const sendArticle = () => {
    setIsLoad(true)
    const body = {
      isMove: isMove,
      newSectionId: sectionId,
      newGroupArticleId: !!articleId ? articleId : null,
    }

    kbController
      .moveArticle(article.sectionId, article.id, body)
      .then(() => {
        handleCompleted()
      })
      .catch(() => {
        handleSnackbar("error");
      })
      .finally(() => {
        handleModal()
        setIsLoad(false)
      })
  }

  const changeSectionId = (el: SelectChangeEvent) => {
    setSectionId(el.target.value)
  }

  const changeArticleId = (el: SelectChangeEvent) => {
    setArticleId(el.target.value)
  }

  useEffect(() => {
    getArticles(sectionId)    
  }, [sectionId]);

  useEffect(() => {
    getSections()
  }, []);

  const createModalButtons = (
    <ModalButtons
      handleCancel={handleModal}
      handleSend={sendArticle}
      fullWidth
      nameButtonSend={isMove ? 'Переместить' : 'Дублировать'}
      justifyContent="flex-end"
      disableSend={!(!!sectionId)}
    />
  );

  return (
    <DialogWrapper
      width="450px"
      openModal={open}
      handleModal={handleModal}
      buttons={createModalButtons}
      contentDividers
    >
      { isLoad ? 
        <LoadProgress/> :
        <Stack gap={4}>
          <Typography variant="h4">
            {isMove ? "Переместить статью" : "Дублировать статью"}
          </Typography>

          <KbList 
            value={sectionId}
            list={sections}
            disabled={isLoad}
            placeholder="Выберите раздел"
            onChange={changeSectionId}
          />

          <KbList 
            value={articleId}
            list={articles}
            disabled={isLoad || !(!!sectionId)}
            placeholder="Выберите группу статей"
            onChange={changeArticleId}
          />

        </Stack>
      }
    </DialogWrapper>
  );
};

export default MoveArticleModal;
