import React, { FC, useContext, useState, useEffect } from "react";

import { AppContext } from "../../../shared/AppContextProvider";
import { KbSectionView } from "../../../../models/kb.models";
import { roles } from "../../../../constants/role";
import { kbController } from "../../../../services/kb.controller";
import { KbContext } from "../../KbContextProvider/KbContextProvider";

import ListArticles from "./ListArticles/ListArticles";

import ArticleModal from "../../KbModal/ArticleModal";
import GroupArticleModal from "../../KbModal/GroupArticleModal";
import SectionModal from "../../KbModal/SectionModal";
import ConfirmModal from "../../../common/Modals/ConfirmModal/ConfirmModal";
import EditPopover from "../../../common/Modals/EditPopover/EditPopover";

import { 
  Paper,
  Typography,
  Stack,
  Grid
} from "@mui/material";

interface SectionWrapperProps {
  section: KbSectionView;
  updSections: () => void;
}

export const SectionWrapper: FC<SectionWrapperProps> = ({
  section,
  updSections,
}) => {
  const { isMobile, user } = useContext(AppContext);
  const { handleSnackbar } = useContext(KbContext);

  const isNotUserRole = user.role !== roles.user;

  const [curSection, setCurSection] = useState<KbSectionView>({
    id: section.id,
    name: section.name,
    description: section.description,
    sequenceNumber: section.sequenceNumber,
    articleCount: section.topArticles.length,
    topArticles: section.topArticles,
  })

  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const handleConfirmModal = () => {
    setConfirmModal((prev) => !prev);
  };

  const [sectionModal, setSectionModal] = useState<boolean>(false);
  const handleSectioneModal = () => {
    setSectionModal((prev) => !prev);
  };

  const [articleModal, setArticleModal] = useState<boolean>(false);
  const handleArticleModal = () => {
    setArticleModal((prev) => !prev);
  };

  const [groupArticleModal, setGroupArticleModal] = useState<boolean>(false);
  const handleGroupArticleModal = () => {
    setGroupArticleModal((prev) => !prev);
  };

  const updSection = () => {
    kbController
      .oneSection(curSection.id)
      .then((res) => {
        setCurSection(
          {
            id: res.data.id,
            name: res.data.name,
            description: res.data.description,
            sequenceNumber: res.data.sequenceNumber,
            topArticles: res.data.topArticles,
            articleCount: res.data.topArticles.length,
          }
        )        
      })
      .catch(() => {
        handleSnackbar("error");
      });
  }

  const handleDeleteSection = () => {
    kbController
      .deleteSection(curSection.id)
      .then(() => {
        updSections();
        handleConfirmModal();
      })
      .catch(() => {
        handleSnackbar("error");
      });
  };

  useEffect(() => {
    setCurSection({
      id: section.id,
      name: section.name,
      description: section.description,
      sequenceNumber: section.sequenceNumber,
      articleCount: section.topArticles.length,
      topArticles: section.topArticles,
    })
  }, [section]);

  const popoverButtons = [
    { name: "Обновить", action: updSection },
    { name: "Редактировать", action: handleSectioneModal },
    { name: "Добавить статью", action: handleArticleModal },
    { name: "Добавить группу для статей", action: handleGroupArticleModal },
    { name: "Удалить", action: handleConfirmModal },
  ];

  const style = {
    p: 3,
    borderRadius: "12px",
    position: "relative",
  };  

  return (
    <>
      <Paper sx={style}>
        <Grid
          container
          columns={{ mobile: isMobile ? 2 : 3 }}
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid container columns={2} mobile={2} spacing={{ mobile: 2 }}>
            <Grid display="flex" gap={4} direction="column" justifyContent="space-between" item mobile={1}>
              <Grid>
                <Typography variant="h4" sx={{ wordWrap: "break-word"}}>
                  {curSection?.name}
                </Typography>
                <Typography variant="body2" mt={3} fontSize={'large'} sx={{ wordWrap: "break-word"}}>
                  {curSection?.description}
                </Typography>
              </Grid>
              {isNotUserRole && (
                <Typography variant="body2" fontSize={'large'}>
                  {curSection?.sequenceNumber}
                </Typography>
              )}
            </Grid>
            <Grid item mobile={1}>
              <Stack gap="12px">
                <ListArticles
                  articles={curSection?.topArticles}
                />
              </Stack>
            </Grid>
          </Grid>
          {isNotUserRole && (
            <Grid mobile={1} container justifyContent="flex-end">
              <EditPopover buttonsData={popoverButtons} />
            </Grid>
          )}
        </Grid>
      </Paper>

      {confirmModal && (
        <ConfirmModal
          openConfirmModal={confirmModal}
          text="Раздел и его статьи будут удалены."
          nameConfirmButton="Удалить"
          handleModal={handleConfirmModal}
          handleConfirm={handleDeleteSection}
        />
      )}
      {sectionModal && (
        <SectionModal
          open={sectionModal}
          type="edit"
          sectionId={curSection.id}
          handleModal={handleSectioneModal}
          handleCompleted={updSections}
        />
      )}
      {articleModal && (
        <ArticleModal
          open={articleModal}
          type="create"
          sectionId={curSection.id}
          handleModal={handleArticleModal}
          handleCompleted={updSection}
        />
      )}
      {groupArticleModal && (
        <GroupArticleModal
          open={groupArticleModal}
          type="create"
          sectionId={curSection.id}
          handleModal={handleGroupArticleModal}
          handleCompleted={updSection}
        />
      )}
    </>
  );
};

export default SectionWrapper;
