import qs from "qs";
import { BaseQueryFn, FetchArgs } from "@reduxjs/toolkit/query";
import { AxiosError, AxiosResponse } from "axios";
import jwtInterceptor from './jwtInterceptor';
import { apiUrlPortal } from "../constants/apiUrl";

export const customBaseQuery: BaseQueryFn<
    FetchArgs,
    AxiosResponse,
    AxiosError
> = async ({ url, method, params, body }) => {
    const res = await jwtInterceptor(`${apiUrlPortal}/${url}`, {
        method,
        ...(method === 'GET' ? { params } : { data: body }),
        paramsSerializer: (params) =>
            qs.stringify(params, { arrayFormat: "repeat" }),
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res;
}


