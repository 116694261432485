import React, { useContext, useEffect, useRef } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import LeftSidebar from "../LeftSidebar/LeftSidebar";
import RightSidebar from "../RightSidebar/RightSidebar";
import Header from "../mobile/Header/Header";
import Navigation from "../LeftSidebar/Navigation/Navigation";
import MorePage from "../mobile/RemainingMenu/RemainingMenu";
import Feed from "../../Feed/Feed";
import MessageComments from "../../Feed/MessageComments/MessageComments";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import Profile from "../../Profile/Profile";
import UserEditing from "../../UserEditing/UserEditing";
import Company from "../../Company/Company";
import PasswordReset from "../../PasswordReset/PasswordReset";
import LoginPage from "../../LoginPage/LoginPage";
import { AppContext } from "../../shared/AppContextProvider";
import useWebSocket from "../../../hooks/useWebSocket";
import { useDispatch } from "react-redux";
import { socketsInitial } from "../../../actions/sockets";
import KnowledgeBase from "../../KnowledgeBase/KnowledgeBase";
import Calendar from "../../Calendar/Calendar";
import Ideas from "../../Ideas/Ideas";
import IdeaComments from "../../Ideas/IdeaComments/IdeaComments";
import Community from "../../Community/Community";
import Notifications from "../../Notifications/Notifications";
import LoginNew from "../../LoginPage/LoginNew/LoginNew";
import Bonuses from '../../Bonuses/Bonuses';

export const PageLayout = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));
  const location = useLocation();
  const { user } = useContext(AppContext);
  const passwordPage =
    location.pathname === "/login" ||
    location.pathname.includes("/reset_password");

  const data = useWebSocket();

  const scrollRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    dispatch(socketsInitial.loggedUsers(data ?? ""));
    /* eslint-disable */
  }, [data]);

  const layoutGridItemsStyle = {
    width: "100%",
    pb: 18,
    height: "calc(100vh - 48px)",
    overflowY: "scroll",
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  };

  return (
    <Box
      sx={{ flexGrow: 1 }}
      m={{ mobile: 0, tablet: 0, laptop: 3, desktop: 3 }}
    >
      <Grid
        container
        direction={{
          mobile: "column-reverse",
          tablet: "column-reverse",
          laptop: "row",
          desktop: "row",
        }}
        columnSpacing={!isMobile ? { laptop: 12, desktop: 36 } : undefined}
        rowSpacing={isMobile ? { mobile: 3, tablet: 3 } : undefined}
        justifyContent={passwordPage ? "center" : undefined}
        wrap="nowrap"
      >
        {user && (
          <Grid data-testid="leftSidebar" item laptop={3}>
            {isMobile ? (
              <Navigation isMobile />
            ) : (
              <LeftSidebar style={layoutGridItemsStyle} />
            )}
          </Grid>
        )}
        <Grid
          item
          laptop={6}
          sx={{ maxWidth: !isMobile ? "50% !important" : "none" }} //For fix swiper library
        >
          <Box
            sx={isMobile ? { pb: 18 } : layoutGridItemsStyle}
            ref={scrollRef}
            id="main-scroll"
          >
            <ScrollToTop scrollRef={scrollRef}>
              {user && (
                <Routes>
                  <Route path="/feed" element={<Feed />} />
                  <Route path="/feed/:feedId" element={<MessageComments />} />
                  <Route path="/more" element={<MorePage />} />
                  <Route path="/profile/:personId/*" element={<Profile />} />
                  <Route path="/profile/edit" element={<UserEditing />} />
                  <Route path="/company" element={<Company />} />
                  <Route path="/knowledge/*" element={<KnowledgeBase />} />
                  <Route path="/calendar/*" element={<Calendar />} />
                  <Route path="/ideas" element={<Ideas />} />
                  <Route path="/bonuses" element={<Bonuses />} />
                  <Route path="/ideas/:ideaId" element={<IdeaComments />} />
                  <Route path="/community/*" element={<Community />} />
                  <Route path="/notifications" element={<Notifications />} />
                  <Route path="*" element={<Navigate to="/feed" replace />} />
                </Routes>
              )}
              {!user && (
                <Routes>
                  <Route
                    path="/reset_password/:resetCode"
                    element={<PasswordReset />}
                  />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/login/new" element={<LoginNew />} />
                  <Route
                    path="/login_link/:loginCode"
                    element={<LoginPage />}
                  />
                  <Route path="*" element={<Navigate to="/login" replace />} />
                </Routes>
              )}
            </ScrollToTop>
          </Box>
        </Grid>
        {user && (
          <Grid item laptop={3}>
            {isMobile ? (
              <Header />
            ) : (
              <RightSidebar style={layoutGridItemsStyle} />
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PageLayout;
