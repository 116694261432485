import React, { FC, useState, useContext, useEffect } from "react";
import ListArticles from "./ListArticles";
import EditPopover from "../../../../common/Modals/EditPopover/EditPopover";
import { KbArticleListView } from "../../../../../models/kb.models";
import { kbController } from "../../../../../services/kb.controller";
import { KbContext } from "../../../KbContextProvider/KbContextProvider";

import ArticleModal from "../../../KbModal/ArticleModal";
import GroupArticleModal from "../../../KbModal/GroupArticleModal";
import ConfirmModal from "../../../../common/Modals/ConfirmModal/ConfirmModal";
import MoveArticleModal from "../../../KbModal/MoveArticleModal";

import { 
  Stack,
  List,
  ListItemButton,
  ListItemText,
  ListItem
} from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


interface SublistArticles {
  parent: KbArticleListView
  parentNestingLevel?: string
  updArticles: (curParent: KbArticleListView) => void
}

export const SublistArticles: FC<SublistArticles> = ({
  parent,
  parentNestingLevel = undefined,
  updArticles = () => {},
}) => {
  const { handleSnackbar } = useContext(KbContext);

  const [isAvailabilSublist, setIsAvailabilSublist] = React.useState(false);
  const [curParent, setCurParent] = useState(parent)
  const [sublist, setSublist] = React.useState<KbArticleListView[] | []>([]);

  const [isOpen, setIsOpen] = React.useState(false);
  const handleSetIsOpen = () => {
    if (!isAvailabilSublist) {
      getSublist()
    }
    setIsOpen(!isOpen);
  };

  const [articleModal, setArticleModal] = useState<boolean>(false);
  const handleArticleModal = () => {
    setArticleModal((prev) => !prev);
  };

  const [createGroupArticleModal, setCreateGroupArticleModal] = useState<boolean>(false);
  const handleCreateGroupArticleModal = () => {
    setCreateGroupArticleModal((prev) => !prev);
  };

  const [editGroupArticleModal, setEditGroupArticleModal] = useState<boolean>(false);
  const handleEditGroupArticleModal = () => {
    setEditGroupArticleModal((prev) => !prev);
  };

  const [moveArticleModal, setMoveArticleModal] = useState<boolean>(false);
  const handleMoveArticleModal = () => {
    setMoveArticleModal((prev) => !prev);
  };

  const [duplicationArticleModal, setDuplicationArticleModal] = useState<boolean>(false);
  const handleDuplicationArticleModal = () => {
    setDuplicationArticleModal((prev) => !prev);
  };

  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const handleConfirmModal = () => {
    setConfirmModal((prev) => !prev);
  };

  const getSublist = () => {    
    kbController
      .articles(curParent.sectionId, curParent.id)
      .then((res) => {
        setSublist(res.data.items)
        setIsAvailabilSublist(true)
      })
      .catch(() => {
        handleSnackbar("error");
      });
  }

  const updSublist = () => {    
    updArticles(curParent);
  }

  const handleDelete = () => {		
    kbController
      .deleteArticle(curParent.id, curParent.sectionId)
      .then(() => {
        updArticles(curParent)   
      })
      .catch(() => {
        handleSnackbar("error");
      })
      .finally(() => {
        handleConfirmModal();
      });
  }

  useEffect(() => {
    setCurParent(parent)
  }, [parent]);

  const popoverButtons = [
    { name: "Обновить", action: getSublist },
    { name: "Добавить статью", action: handleArticleModal },
    { name: "Добавить группу статей", action: handleCreateGroupArticleModal },
    { name: "Редактировать группу статей", action: handleEditGroupArticleModal },
    { name: "Переместить", action: handleMoveArticleModal },
    { name: "Дублировать", action: handleDuplicationArticleModal },
    { name: "Удалить", action: handleConfirmModal },
  ];

  return (
    <>
      <List sx={{p: 0}}>
        <ListItemButton sx={{pl: 0}}>
            <ListItem onClick={handleSetIsOpen}>
              <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
                <ListItemText primary={parentNestingLevel + ' ' + curParent.name} />
                {isOpen ? <ExpandLess /> : <ExpandMore />}
              </Stack>
            </ListItem>
            <EditPopover buttonsData={popoverButtons} />
        </ListItemButton>
        {isOpen && (!!sublist.length) && (
          <ListArticles 
            articles={sublist}
            nestingLevel={parentNestingLevel}
            updSubArticle={getSublist}
          />
        )}
      </List>

      {articleModal && (
        <ArticleModal
          open={articleModal}
          type="create"
          sectionId={curParent.sectionId}
          articleId={curParent.id}
          parentArticleId={curParent.id}
          handleModal={handleArticleModal}
          handleCompleted={getSublist}
        />
      )}
      {createGroupArticleModal && (
        <GroupArticleModal
          open={createGroupArticleModal}
          type="create"
          sectionId={curParent.sectionId}
          articleId={curParent.id}
          parentArticleId={curParent.id}
          handleModal={handleCreateGroupArticleModal}
          handleCompleted={getSublist}
        />
      )}
      {editGroupArticleModal && (
        <GroupArticleModal
          open={editGroupArticleModal}
          type="edit"
          sectionId={curParent.sectionId}
          articleId={curParent.id}
          parentArticleId={curParent.parentArticleId ? curParent.parentArticleId : null}
          handleModal={handleEditGroupArticleModal}
          handleCompleted={updSublist}
        />
      )}
      {moveArticleModal && (
        <MoveArticleModal
          type="move"
          article={curParent}
          open={moveArticleModal}
          handleCompleted={updSublist}
          handleModal={handleMoveArticleModal}
        />
      )}
      {duplicationArticleModal && (
        <MoveArticleModal
          type="duplication"
          article={curParent}
          open={duplicationArticleModal}
          handleCompleted={updSublist}
          handleModal={handleDuplicationArticleModal}
        />
      )}
      {confirmModal && (
        <ConfirmModal
          openConfirmModal={confirmModal}
          nameConfirmButton="Удалить"
          text="Группа и вложенные статьи будут удалены"
          handleConfirm={handleDelete}
          handleModal={handleConfirmModal}
        />
      )}
    </>
  );
};

export default SublistArticles;