import React, {FC, memo, useEffect, useState} from 'react';
import {formCreateActivity} from './styles/formCreateActivity';
import {Box, Typography} from '@mui/material';
import {activityController} from '../../../../services/activity.controller';
import DialogWrapper from '../../../common/Modals/DialogWrapper/DialogWrapper';
import CustomSelect from '../../../common/Inputs/CustomSelect/CustomSelect';
import InputFilled from '../../../common/Inputs/InputFilled/InputFilled';
import {personActivityController} from '../../../../services/person_activity.controller';
import {useAppSelector} from '../../../../hooks/useAppSelector';
import {GiftView} from '../../../../models/gifts.models';
import {getPointsText} from '../../helpers/getPointsText';
import {useDispatch} from 'react-redux';
import {showSnackbar} from '../../../../reducer/snackbarSlice';
import {ModalActionButtons} from '../ModalActionButtons/ModalActionButtons';

interface FormCreateActivityProps {
    toggleModal: (modal: 'actives' | 'gift', state: boolean) => () => void;
    openModal: boolean;
}

export const FormCreateActivity: FC<FormCreateActivityProps> = ({openModal, toggleModal}) => {
    const dispatch = useDispatch()
    const [formData, setFormData] = useState<{
        activity: string;
        message: string;
        selectActivity?: GiftView;
    }>({
        activity: '',
        message: '',
        selectActivity: undefined,
    });
    const updateFormData = (key: keyof typeof formData, value: any) => {
        setFormData((prev) => ({
            ...prev,
            [key]: value,
        }));
    };
    const currentUser = useAppSelector((state) => state.profileReducer.profile);
    const {data} = activityController.useGetAllActivityQuery('')
    const activities = data?.items?.map(item => ({
        id: item.id,
        name: item.title,
    }))
    const chosenActivity = (value: string) => {
        updateFormData('activity', value)
    }
    const handleClose = () => {
        setFormData({
            activity: '',
            message: '',
            selectActivity: undefined,
        });
        toggleModal('actives', false)();

    }
    const [createReviewActivity] = personActivityController.useCreateReviewActivityMutation()
    const handleCreateActivity = async () => {
        try {
            await createReviewActivity({
                activityId: formData.activity,
                message: formData.message,
                ownerId: currentUser.id,
            }).unwrap();

            dispatch(showSnackbar({
                message: 'Заявка успешно создана!',
                type: 'success',
                position: 'center',
            }));

            handleClose();

        } catch (error) {
            dispatch(showSnackbar({
                message: 'Ошибка: заявка не создана. Проверьте данные.',
                type: 'error',
                position: 'center',
            }));
        }
    };

    useEffect(() => {
        if (!formData.activity) return;
        const selected = data?.items?.find(item => item.id === formData.activity)
        updateFormData('selectActivity', selected);
    }, [formData.activity]);

    return (
        <DialogWrapper
            openModal={openModal}
            handleModal={handleClose}
            width="532px"
            contentDividers
            buttons={<ModalActionButtons
                onCancel={handleClose}
                onSubmit={handleCreateActivity}/>}
            header={'Заявка на начисление бонусов'}
        >
            <Box
                display="flex"
                alignItems="center"
                gap={2}>
                <Typography sx={formCreateActivity.text}>Выберите тип активность</Typography>
                <Box width={'44%'}>
                    <CustomSelect
                        items={activities ?? []}
                        value={formData.activity}
                        onChange={(e) => chosenActivity(e.target.value)}
                    />
                </Box>
                <Typography
                    sx={formCreateActivity.text}>
                    {formData.selectActivity?.cost ?
                        `${formData.selectActivity?.cost} ${getPointsText(formData.selectActivity.cost)}` :
                        ''
                    }
                </Typography>
            </Box>
            <Box
                mt={2}
            >
                <Typography sx={formCreateActivity.text}>Введите ваше сообщение</Typography>
                <InputFilled
                    margin={'8px 0'}
                    multiline value={formData.message}
                    handleValue={e => updateFormData('message', e.target.value)}
                />
            </Box>
        </DialogWrapper>
    );
};

export default memo(FormCreateActivity);