import {SystemStyleObject, Theme} from '@mui/system';

export const heroButton: { [key: string]: SystemStyleObject<Theme> } = {
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '10px',
        padding: '12px',
        width: '100%',
        boxSizing: 'border-box',
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: 'rgba(203, 225, 255, 0.17)'
        }
    },
    button__content: {
        display: 'flex',
        alignItems: 'center',
    },
    button__title: {
        fontSize: '13px',
        lineHeight: '12px',
    },
    button__desc: {
        fontSize: '11px',
        color: 'rgba(229, 235, 242, 0.4)',
    },
    button__icon: {
        width: 20,
        height: 20,
        color: 'rgba(230, 237, 245, 1)',
    },
    button__icon__wrapper: {
        height:30,
        width: 30,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease-in-out',
        marginRight: '10px'
    }
}