import React, { FC, useContext } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import { DesktopPopover } from "./DesktopPopover/DesktopPopover";
import { AppContext } from "../../../shared/AppContextProvider";
import { MobilePopup } from "./MobilePopup/MobilePopup";

export type buttonInfo = {
  name: string;
  action: (e?: any) => void;
  disabled?: boolean;
  startIcon?: React.ReactElement;
};

interface EditPopoverProps {
  buttonsData: buttonInfo[];
  customButton?: React.ReactElement;
  addOpenAction?: () => void;
  addCloseAction?: () => void;
}

export const EditPopover: FC<EditPopoverProps> = ({
  customButton,
  addOpenAction,
  addCloseAction,
  buttonsData,
}) => {
  const { isMobile } = useContext(AppContext);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const isOpen = Boolean(anchorEl);
  const id = isOpen ? "edit-popover" : undefined;

  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    addOpenAction?.();
    if (isMobile) {
      return setOpenModal(true);
    }
    return setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    addCloseAction?.();
    if (isMobile) {
      return setOpenModal(false);
    }
    return setAnchorEl(null);
  };

  const modifiedCustomButton = customButton
    ? React.cloneElement(customButton, {
        onClick: handleClick,
      })
    : null;

  const styleDesktopeButton = {
    p: "12px",
    "&:hover": {
      backgroundColor: "rgba(51, 146, 255, 0.25)",
    },
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "20px",
  };

  const styleMobileButton = {
    p: 2,
    borderBottom: "1px solid rgba(241, 247, 255, 0.12)",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
    "&:last-child": { borderBottom: "none" },
  };

  const styleButton = {
    color: "rgba(230, 237, 245, 1)",
    justifyContent: "flex-start",
    borderRadius: 0,
    ...(isMobile && styleMobileButton),
    ...(!isMobile && styleDesktopeButton),
  };

  const popoverButtons = (
    <>
      {buttonsData?.map((button, index) => (
        <Button
          key={index}
          sx={styleButton}
          onClick={(e: any) => {
            button.action(e);
            handleClose();
          }}
          disabled={button.disabled}
          startIcon={button.startIcon}
        >
          {button.name}
        </Button>
      ))}
    </>
  );

  return (
    <div className="EditPopover">
      {customButton ? (
        modifiedCustomButton
      ) : (
        <IconButton
          color="secondary"
          aria-describedby={id}
          onClick={handleClick}
          sx={{ width: "32px", height: "32px" }}
        >
          <MoreVertIcon />
        </IconButton>
      )}
      <DesktopPopover
        popoverButtons={popoverButtons}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        isOpen={isOpen}
        id={id}
      />
      <MobilePopup
        popoverButtons={popoverButtons}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </div>
  );
};

export default EditPopover;
