import React, { FC } from "react";

import SvgIcon from "@mui/material/SvgIcon";

import { ReactComponent as LentaIcon } from "../../../../assets/icons/lenta.svg";
import { ReactComponent as KnowledgeIcon } from "../../../../assets/icons/knowledge.svg";
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/calendar.svg";
import { ReactComponent as CompanyIcon } from "../../../../assets/icons/company.svg";
import { ReactComponent as IdeasIcon } from "../../../../assets/icons/ideas.svg";
import { ReactComponent as LentaInactiveIcon } from "../../../../assets/icons/lentaInactive.svg";
import { ReactComponent as KnowledgeInactiveIcon } from "../../../../assets/icons/knowledgeInactive.svg";
import { ReactComponent as CalendarInactiveIcon } from "../../../../assets/icons/calendarInactive.svg";
import { ReactComponent as CompanyInactiveIcon } from "../../../../assets/icons/companyInactive.svg";
import { ReactComponent as IdeasInactiveIcon } from "../../../../assets/icons/ideasInactive.svg";
import { ReactComponent as MoreIcon } from "../../../../assets/icons/more.svg";
import { ReactComponent as MoreInactiveIcon } from "../../../../assets/icons/moreInactive.svg";
import { ReactComponent as SettingsIcon } from "../../../../assets/icons/settings.svg";
import { ReactComponent as SettingsInactiveIcon  } from "../../../../assets/icons/settingsInactive.svg";
import BottomNavigations from "../../mobile/BottomNavigations/BottomNavigations";
import MenuList from "../../../common/MenuList/MenuList";
import { MenuListItems } from "../../../../types/MenuListItems.types";
import ServicesList from '../ServicesList/ServicesList';

interface NavigationProp {
  isMobile?: boolean;
  forRemainingMenu?: boolean;
}

export const Navigation: FC<NavigationProp> = ({
  isMobile = false,
  forRemainingMenu,
}) => {
  const iconProps = {
    sx: {
      width: "32px",
      height: "32px",
    },
    viewBox: "0 0 32 32",
  };

  const navigationItems: MenuListItems = [
    {
      name: "Лента",
      icon: <SvgIcon component={LentaIcon} {...iconProps} />,
      inactiveIcon: <SvgIcon component={LentaInactiveIcon} {...iconProps} />,
      href: "/feed",
    },
    {
      name: "Календарь",
      icon: <SvgIcon component={CalendarIcon} {...iconProps} />,
      inactiveIcon: <SvgIcon component={CalendarInactiveIcon} {...iconProps} />,
      href: "/calendar",
    },
    {
      name: "Компания",
      icon: <SvgIcon component={CompanyIcon} {...iconProps} />,
      inactiveIcon: <SvgIcon component={CompanyInactiveIcon} {...iconProps} />,
      href: "/company",
    },
    {
      name: "Идеи",
      icon: <SvgIcon component={IdeasIcon} {...iconProps} />,
      inactiveIcon: <SvgIcon component={IdeasInactiveIcon} {...iconProps} />,
      href: "/ideas",
    },
    {
      name: "База знаний",
      icon: <SvgIcon component={KnowledgeIcon} {...iconProps} />,
      inactiveIcon: (
        <SvgIcon component={KnowledgeInactiveIcon} {...iconProps} />
      ),
      href: "/knowledge",
    },
    {
      name: "Бонусы",
      icon: <SvgIcon component={SettingsIcon} {...iconProps} />,
      inactiveIcon: <SvgIcon component={SettingsInactiveIcon} {...iconProps} />,
      href: "/bonuses",
    },
    {
      name: "Ещё",
      icon: <SvgIcon component={MoreIcon} {...iconProps} />,
      inactiveIcon: <SvgIcon component={MoreInactiveIcon} {...iconProps} />,
      href: "/more",
    },
  ];

  const filteredItems = forRemainingMenu
    ? navigationItems.filter((item, index) => ![0, 1, 2, 3, 5].includes(index))
    : navigationItems.filter((item, index) => index !== 6);

  return (
    <>
      {isMobile ? (
        <BottomNavigations items={navigationItems} />
      ) : (
        <>
          <MenuList
            testid="mainMenu"
            items={filteredItems}
            forRemainingMenu={forRemainingMenu ?? false}
          />
          <ServicesList />
        </>
      )}
    </>
  );
};

export default Navigation;
