import React, { FC, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { KbArticleListView } from "../../../../../models/kb.models";
import { kbController } from "../../../../../services/kb.controller";
import { KbContext } from "../../../KbContextProvider/KbContextProvider";

import EditPopover from "../../../../common/Modals/EditPopover/EditPopover";
import ArticleModal from "../../../KbModal/ArticleModal";
import ConfirmModal from "../../../../common/Modals/ConfirmModal/ConfirmModal";
import MoveArticleModal from "../../../KbModal/MoveArticleModal";

import { 
  ListItemText,
  ListItemButton ,
  Link
} from '@mui/material';

interface ListArticlesProps {
  article: KbArticleListView;
  nestingLevel?: string;
  updArticles: (curArticle: KbArticleListView) => void;
}

export const ItemArticles: FC<ListArticlesProps> = ({
  article,
  nestingLevel = undefined,
  updArticles = () => {},
}) => {
  const { handleSnackbar } = useContext(KbContext);
  const navigate = useNavigate();
  const emptyArticleName = "no name";

  const [curArticle, setCurArticle] = useState(article)

  const [createArticleModal, setCreateArticleModal] = useState<boolean>(false);
  const handleArticleModal = () => {
    setCreateArticleModal((prev) => !prev);
  };

  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const handleConfirmModal = () => {
    setConfirmModal((prev) => !prev);
  };

  const [moveArticleModal, setMoveArticleModal] = useState<boolean>(false);
  const handleMoveArticleModal = () => {
    setMoveArticleModal((prev) => !prev);
  };

  const [duplicationArticleModal, setDuplicationArticleModal] = useState<boolean>(false);
  const handleDuplicationArticleModal = () => {
    setDuplicationArticleModal((prev) => !prev);
  };

  const updCurArticles = () => {
    updArticles(curArticle);
  }

  const handleDelete = () => {		
    kbController
      .deleteArticle(curArticle.id, curArticle.sectionId)
      .then(() => {
        updArticles(curArticle);
      })
      .catch(() => {
        handleSnackbar("error");
      })
      .finally(() => {
        handleConfirmModal();
      })
  }

  const routeToArticle = (
    event: React.MouseEvent,
    article: KbArticleListView,
    sectionId: string
  ) => {
    event.preventDefault();
    localStorage.setItem(
      "article",
        JSON.stringify({ articleId: article.id, sectionId: sectionId })
    );
    navigate(`/knowledge/${article.name ? article.name : emptyArticleName}`);
  };

  useEffect(() => {
    setCurArticle(article)
  }, [article]);

  const popoverButtons = [
    { name: "Редактировать", action: handleArticleModal },
    { name: "Переместить", action: handleMoveArticleModal },
    { name: "Дублировать", action: handleDuplicationArticleModal },
    { name: "Удалить", action: handleConfirmModal },
  ]; 

  const styleLink = {
    width: "fit-content",
    textDecoration: "none",
    overflowWrap: "anywhere",
    "&:hover": {
        cursor: "pointer",
    }
  };

  const styleButton = {
    "&:hover .MuiButtonBase-root": {
        display: "flex",
    },
    ".MuiButtonBase-root": {
        display: "none",
    },
  };

  return (
    <>
      <ListItemButton sx={styleButton}>
        <ListItemText>
          <Link
            variant="body2"
            sx={styleLink}
            onClick={(event) => routeToArticle(event, curArticle, curArticle.sectionId)}
          >
              {nestingLevel} {curArticle.name ? curArticle.name : emptyArticleName}
          </Link>
        </ListItemText>
        <EditPopover buttonsData={popoverButtons} />
      </ListItemButton>

      {createArticleModal && (
        <ArticleModal
          open={createArticleModal}
          type="edit"
          sectionId={curArticle.sectionId}
          articleId={curArticle.id}
          parentArticleId={curArticle.parentArticleId ? curArticle.parentArticleId : null}
          handleModal={handleArticleModal}
          handleCompleted={updCurArticles}
        />
      )}
      {moveArticleModal && (
        <MoveArticleModal
          type="move"
          article={curArticle}
          open={moveArticleModal}
          handleModal={handleMoveArticleModal}
          handleCompleted={updCurArticles}
        />
      )}
      {duplicationArticleModal && (
        <MoveArticleModal
          type="duplication"
          article={curArticle}
          open={duplicationArticleModal}
          handleModal={handleDuplicationArticleModal}
          handleCompleted={updCurArticles}
        />
      )}
      {confirmModal && (
        <ConfirmModal
          openConfirmModal={confirmModal}
          nameConfirmButton="Удалить"
          text="Статья будет удалена"
          handleModal={handleConfirmModal}
          handleConfirm={handleDelete}
        />
      )}
    </>
  );
};

export default ItemArticles;