import React, { FC } from "react";

import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";

interface List {
  id: string;
  name: string;
  sequenceNumber?: number;
  fullSequenceNumber?: string;
}

interface SectionModalProps {
  value: string;
  list: List[];
  disabled: boolean;
  placeholder?: string;
  onChange: (e: SelectChangeEvent) => void;
}

export const KbList: FC<SectionModalProps> = ({
  value = '',
  list = [],
  disabled = false,
  placeholder,
  onChange,
}) => {

  const styleMenu = {
    "& .MuiMenu-paper": {
      backgroundColor: "#212633",
    },
  };

  return (
    <FormControl disabled={disabled} variant="standard" sx={{ m: 1, minWidth: 120 }}>
      {placeholder && <Typography mb={'12px'}>{placeholder}</Typography>}

      <Select
        value={value}
        onChange={onChange}
        MenuProps={{
          sx: styleMenu
        }}
      >
        {list?.map((el) => (
          <MenuItem value={el.id}>{(el?.sequenceNumber || el?.fullSequenceNumber) + ' ' + el.name}</MenuItem>
        ))}
      </Select>

    </FormControl>
  );
};

export default KbList;
