import React, {FC} from 'react';
import {Box, Fab} from '@mui/material';
import {headerButtons} from './styles/headerButtons';

const buttonLabels: string[] = [
    'Подарки за бонусы',
    'Накопить бонусы легко',
    'Управление активностями',
    'Управление подарками'
]

export const HeaderButtons: FC = () => {
    return (
        <Box sx={headerButtons.container}>
            {buttonLabels.map((label) => (
                <Fab key={label} variant="extended" size="medium" sx={headerButtons.button}>
                    {label}
                </Fab>
            ))}
        </Box>
    );
};

export default HeaderButtons;